/* eslint-disable max-lines */
import { RawDraftContentState } from 'draft-js';

import { User } from '../../models/User';
import { CompanyUserRole } from '../../modules/CompanyTeam/Constants';
import {
  ActivityLogAction,
  ApplicationSource,
  ApplicationStatus,
  CandidateStatus,
  CompanyStatus,
  EDUCATION_LEVEL,
  EXPERIENCE_TYPE,
  GENDER,
  JobBoostStatus,
  JobStatus,
  QUESTION_FORMAT,
  WorkArrangementOption,
} from '../constants';
import { ApplicationScreeningAnswer } from './screening-question';

type JobDeprioritizeInfo = {
  isDeprioritized: boolean;
};

type ApplicationCountBreakdown = {
  unread: number;
};

type VIPCandidateInformation = {
  required: {
    profilePic: boolean;
  };
};

export const JobScreeningQuestionSelectedQuestions = {
  INTRO_VIDEO: 'INTRO_VIDEO',
  SKILL_PROFICIENCY: 'SKILL_PROFICIENCY',
  WORK_EXPERIENCE_DURATION: 'WORK_EXPERIENCE_DURATION',
  INDUSTRY_EXPERIENCE: 'INDUSTRY_EXPERIENCE',
  LOCATION_SUITABILITY: 'LOCATION_SUITABILITY',
  DOCUMENT_CERTIFICATE_AVAILABILITY: 'DOCUMENT_CERTIFICATE_AVAILABILITY',
  WORKING_POLICY_SUITABILITY: 'WORKING_POLICY_SUITABILITY',
  CUSTOM_PLAIN_TEXT: 'CUSTOM_PLAIN_TEXT',
} as const;

export type JobScreeningQuestionSelectedQuestions =
  (typeof JobScreeningQuestionSelectedQuestions)[keyof typeof JobScreeningQuestionSelectedQuestions];

export const ScreeningQuestionSelectedQuestionsResponse = {
  ...JobScreeningQuestionSelectedQuestions,
  CUSTOM_PLAIN_TEXT: 'CUSTOM_PLAIN_TEXT',
} as const;

export type ScreeningQuestionSelectedQuestionsResponse =
  (typeof ScreeningQuestionSelectedQuestionsResponse)[keyof typeof ScreeningQuestionSelectedQuestionsResponse];

export type JobScreeningQuestions = {
  selectedQuestions: ScreeningQuestionSelectedQuestionsResponse[];
  introVideoPreferredLanguageDetail?: {
    code: string;
    name: string;
    translationKey: string | null;
  };
  industries: Industry[];
  documentAndCertificates: {
    id: string;
    name: string;
  }[];
  customQuestionPlainTexts?: string[];
};

// This Job interface is used for both getJobById and getJobDraftById
export interface Job {
  id: string;
  status: JobStatus;
  companyId: string;
  CompanyId: string;
  title: string;
  expiryDate?: string;
  createdAt: string;
  updatedAt: string;
  closedAt?: string;
  type: string;
  links?: {
    currentCompanyJobSlotsSubscription?: CurrentCompanyJobSlotsSubscription;
  } & Record<string, unknown>;
  Company?: Company;
  company?: Company;
  salaries?: JobSalary[];
  CountryCode: string;
  JobSalaries?: JobSalary[];
  minYearsOfExperience: number;
  maxYearsOfExperience: number;
  JobCategoryId: number;
  workArrangementOption: WorkArrangementOption;
  CityId: number;
  acceptsForeignApplications: boolean;
  descriptionRaw: RawDraftContentState;
  skills: { skill: JobSkill }[];
  shouldShowSalary: boolean;
  jobExtensionDetails?: JobExtensionDetails;
  descriptionJsonString?: string;
  shouldPublishOnLinkedin: boolean;
  benefits: JobBenefit[];
  receiverIds?: string[];
  interviewProcessRaw: RawDraftContentState;
  interviewProcessJsonString?: string;
  isDraft?: boolean;
  isRegularJob?: boolean;
  areMandatoryDetailsComplete?: boolean;
  city?: { name: string; id: number; CountryCode: string };
  country?: { name: string; code: string; isWhitelisted?: boolean };
  HierarchicalJobCategoryId?: string | null;
  hierarchicalJobCategory?: HierarchicalJobCategory;
  category?: { id: number; name: string };
  location?: HierarchicalLocation;
  DistrictId?: string | null;
  parentLocations?: (HierarchicalLocation & {
    administrativeLevelPrefix: string | null;
  })[];
  educationLevel: EDUCATION_LEVEL;
  CreatorId?: string;
  creatorId?: string;
  isHot?: boolean;
  jobBoost?: JobBoostDetail;
  isJobBoostEligible?: boolean;
  minAge: number | null;
  maxAge: number | null;
  gender: 'MALE' | 'FEMALE' | null;
  unfilteredSkills?: { skill: Skill }[]; // JobDraft does not have this field
  isLocationRequiredForCandidate?: boolean;
  isEducationRequiredForCandidate?: boolean;
  isResumeRequiredForCandidate?: boolean;
  jobDeprioritizeInfo?: JobDeprioritizeInfo;
  applicationCountBreakdown?: ApplicationCountBreakdown;
  maybeHaveArchivedApplications?: boolean;
  screeningQuestions?: JobScreeningQuestions;
  screeningQuestionsEnabled?: boolean;
  isJobPremium?: boolean; // JobDraft does not have this field
  vipCandidateInformation?: VIPCandidateInformation;
  externalApplyURL?: string;
}

export interface JobDashboard extends Job {
  links: {
    jobSalaries: Job['salaries'];
    jobBenefits: Job['benefits'];
    groups: any[];
    city: {
      name: string;
      id: number;
    };
    country: {
      name: string;
      code: string;
    };
    location: HierarchicalLocation;
  };
  locationParents: HierarchicalLocation[];
  applicationStatusBreakdown: StatusBreakdown;
  viewCount: number;
}

export interface JobCard extends Job {
  city: { id: number; name: string; CountryCode: string };
  country: { code: string; name: string };
  breakdown: StatusBreakdown;
  viewCount: number;
  category: { id: number; name: string };
  children?: HierarchicalJobCategory[];
  leaves?: HierarchicalJobCategory[];
}

export interface JobBoostDetail {
  id: string;
  jobId: string;
  status: JobBoostStatus;
  boostedAt: string;
  boostEndsAt: string;
}

export interface DraftJob extends Omit<Job, 'salaries' | 'skills' | 'links'> {
  links: {
    jobSalaryDrafts?: JobSalary[];
    skills?: Skill[];
  };
}

export type JobExtensionDetails = {
  extendExpiryInDays: number;
};

export interface JobSalary {
  CurrencyCode: string;
  minAmount: number;
  maxAmount: number;
  salaryType: string;
  salaryMode: string;
}

export type StatusBreakdown = {
  [key in ApplicationStatus]: number;
};

export type WorkExperience = {
  id: string;
  title: string;
  organization: string;
  startDate: string;
  endDate: string;
  isPresent: boolean;
  description: string | null;
  type: EXPERIENCE_TYPE;
};

export interface File {
  fileName: string;
  filePathname: string;
}

export const SocialNetworkTypes = {
  Behance: 'Behance',
  CodePen: 'CodePen',
  Facebook: 'Facebook',
  GitHub: 'GitHub',
  Instagram: 'Instagram',
  LinkedIn: 'LinkedIn',
  Twitter: 'Twitter',
  Tiktok: 'Tiktok',
  Vimeo: 'Vimeo',
  Youtube: 'Youtube',
};
export type SocialNetworkTypes =
  (typeof SocialNetworkTypes)[keyof typeof SocialNetworkTypes];

export interface Portfolio {
  website: string | null;
  attachment: File[] | null;
  socialNetwork?: Record<SocialNetworkTypes, string | undefined>;
}

export interface Preference {
  id: string;
  salaryLatest: number | null;
  salaryExpectation: number | null;
  internship: boolean;
  fullTime: boolean;
  partTime: boolean;
  projectBased: boolean;
  preferredStartDate: string | null;
  CurrencyCode: string;
  UserId: string;
  willingToWorkRemotely: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface Skill {
  id: string;
  name: string;
  translationName?: string;
}

export type JobSkill = Pick<Skill, 'id' | 'name'>;

export interface JobLocationPreference {
  id: string;
  CountryCode: string;
  CityId: number;
  UserId: string;
  LocationId: string;
}

export interface Experience {
  id: string;
  title: string;
  organization: string;
  notes: string | null;
  activity: string | null;
  achievement: string | null;
  startDate: string | null;
  endDate: string | null;
  isPresent: boolean | null;
  educationLevel: EDUCATION_LEVEL;
  type: EXPERIENCE_TYPE;
  School: School | null;
  Study: Study | null;
  fieldOfStudy: string | null;
  school: string | null;
}

export interface School {
  id: string;
  name: string;
}

export interface Study {
  id: string;
  name: string;
  translationKey: string | null;
}

export interface Education {
  id: string;
  educationLevel: EDUCATION_LEVEL;
  SchoolId: string;
  StudyId: string;
  startDate: string;
  endDate: string;
  isPresent: boolean;
}

export interface Applicant {
  id: string;
  profilePic: string | null;
  profilePics: { key: string }[] | null;
  firstName: string;
  lastName: string;
  workDurationInMonths: number;
  CityId: number;
  CountryCode: string;
  email: string;
  name: string;
  ugcFullName: string;
  latestEducationExperience: TalentEducation | null;
  latestWorkExperience: WorkExperience | null;
  birthDate: string;
  phone: string;
  intro: string;
  Nationality: string;
  gender: GENDER;
  portfolio?: Portfolio;
  portfolioDetail?: {
    isAvailable: {
      website: boolean;
      attachment: boolean;
      socialNetwork: boolean;
    };
  };
  Preferences?: Preference[] | null;
  Skills: Skill[];
  JobLocationPreferences: JobLocationPreference[];
  JobRolePreferences: JobRolePreference[];
  Experiences: Experience[];
  highestEducationLevel: EDUCATION_LEVEL | null;
  LocationId: string;
  location: HierarchicalLocation | string;
  locationParents: HierarchicalLocation[];
  City: {
    name: string;
  };
  Country: {
    name: string;
  };
  updatedAt: string;
  lastSeen: string;
  careerStartDate?: Date;
  Education: Experience[];
  educations?: Experience[];
  candidateStatus: CandidateStatus;
  // api v2 fields
  locationFormattedNames: {
    locationId: string;
    formattedName: string;
  }[];
}

export interface Question {
  name: string;
  label: string;
  labelLokaliseKey: string;
  responseFormat: QUESTION_FORMAT;
}

export interface Answer {
  response: string[] | string | number;
  responseLokaliseKey: string[] | string | null;
}

export type ApplicantWhatsAppDetails = {
  whatsAppNumber: string | null;
  isAvailable: boolean;
};

export const ApplicationAccessLevel = {
  PARTIAL: 'PARTIAL',
  FULL_ACCESS: 'FULL_ACCESS',
} as const;

export type ApplicationAccessLevel =
  (typeof ApplicationAccessLevel)[keyof typeof ApplicationAccessLevel];
export interface ApplicationDetails {
  id: string;
  status: ApplicationStatus;
  resume?: string;
  links?: {
    job?: Job;
    jobLocation?: HierarchicalLocation;
  };
  Applicant: Applicant;
  ApplicantId: string;
  JobId?: string;
  source?: ApplicationSource;
  createdAt: string;
  expectedSalary?: string;
  Questions: Question[];
  Answers: Answer[];
  phone?: string;
  whatsAppDetails: ApplicantWhatsAppDetails;
  firstReadAt?: string;
  screeningQuestion?: {
    matchedQuestionCount: number;
    totalQuestionCount: number;
    hasScreeningAnswers: boolean;
    applicationScreeningAnswer?: ApplicationScreeningAnswer;
  };
  employerMetadata?: {
    accessLevel: ApplicationAccessLevel;
  };
  isExpiringSoon?: boolean;

  // client side properties
  isApplicationLockedForStatusChange?: boolean;
}

export interface ActivityLog {
  employerFirstName?: string;
  applicationStatus?: ApplicationStatus;
  firstName?: string;
  jobName?: string;
  action: ActivityLogAction;
  date: Date;
}

export const MonetisationMode = {
  SUBSCRIPTION_MODE: 'SUBSCRIPTION_MODE',
  NON_MONETISATION_MODE: 'NON_MONETISATION_MODE',
} as const;

export type MonetisationMode =
  (typeof MonetisationMode)[keyof typeof MonetisationMode];

export const VerificationMethod = {
  PROOF_OF_OWNERSHIP: 'PROOF_OF_OWNERSHIP',
  LEGAL_DOCUMENTS: 'LEGAL_DOCUMENTS',
} as const;

export type VerificationMethod =
  (typeof VerificationMethod)[keyof typeof VerificationMethod];

export type CompanyVerificationRequestLegalDocument = {
  verificationMethod: typeof VerificationMethod.LEGAL_DOCUMENTS;
  companyNpwpDocument: string;
  companyNpwpNumber: string;
  companyNibNumber: string | null;
};

export const BusinessVerificationLocationType = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
} as const;

export type BusinessVerificationLocationType =
  (typeof BusinessVerificationLocationType)[keyof typeof BusinessVerificationLocationType];

export type CompanyVerificationRequestProofOfOwnership = {
  verificationMethod: typeof VerificationMethod.PROOF_OF_OWNERSHIP;
  personalNpwpDocument: string | null;
  personalNpwpNumber: string | null;
  ktpNumber: string | null;
  ktpDoc: string | null;
  personalNibNumber: string | null;
  businessLocationType: BusinessVerificationLocationType;
  businessImages: string[];
};

export type CompanyVerificationBenefits = {
  activeJobCount: number;
  verifiedBadge: boolean;
  paidFeatures: boolean;
};
export type CompanyVerificationMethod = {
  verificationMethod: VerificationMethod;
  benefits: CompanyVerificationBenefits;
  popularityTag: {
    key: string;
    defaultMessage: string;
  } | null;
};

export type CompanyVerificationRequest = {
  id: string;
  createdAt: string;
  approvedAt: string | null;
  company: Pick<Company, 'id' | 'status'>;
  companyVerificationMethod: CompanyVerificationMethod;
  rejectionReasonText: string | null;
  document:
    | CompanyVerificationRequestLegalDocument
    | CompanyVerificationRequestProofOfOwnership;
};

export type CompanyTierUpgrade = {
  isUpgradeInitiated: boolean;
  isUpgradeRejected: boolean;
  availableTierUpgrade: CompanyVerificationMethod[];
};

export type CompanyVerificationInfo = {
  isInitiated: boolean;
  isRejected: boolean;
  lastVerificationRequest: CompanyVerificationRequest | null;
  tierUpgrade: CompanyTierUpgrade | null;
};
export interface Company {
  id: string;
  name: string;
  status: CompanyStatus;
  legalDocument: string | null;
  legalRegistrationNumber: string | null;
  logo: string | null;
  CountryCode: string;
  currentCompanyJobSlotsSubscription?: CurrentCompanyJobSlotsSubscription;
  IndustryId: number;
  size: string;
  createdAt: string;
  LocationId: string;
  CityId: number;
  address: string;
  creditBalance?: number;
  recommendedTalentBalance?: number;
  hotJobBalances?: {
    variant: string;
    balance: number;
  }[];
  talentSearchBalance?: number;
  fullProfileAccessStatus?: UnleashFlag;
  defaultFullProfileAccessBalance?: number;
  verificationInfo?: CompanyVerificationInfo;
}

export type UnleashFlagVariant = {
  type: string;
  value: string;
};

export type UnleashFlag = {
  name: string;
  variant?: UnleashFlagVariant;
};

export type CompanyWithMonetisationMode = Company & {
  monetisationMode: MonetisationMode;
  LocationId?: string;
};

export interface Country {
  code: string;
  name: string;
  callingCode: string[];
}

export interface City {
  id: number;
  CountryCode: string;
  name: string;
}

export interface JobCategory {
  id: number;
  name: string;
}

export interface HierarchicalJobCategory {
  id: string;
  name: string;
  defaultName: string;
  level: number;
  children: HierarchicalJobCategory[];
  leaves: HierarchicalJobCategory[];
  parents?: HierarchicalJobCategory[];
}

export interface JobBenefit {
  benefit: string;
  description: string;
  label: string;
  logo: string;
  title: string;
}

export interface HierarchicalLocation {
  id: string;
  administrativeLevelName: string;
  administrativeLevelPrefix: string | null;
  defaultName: string | null;
  name: string;
  formattedName: string;
  level: number;
  children?: HierarchicalLocation[];
  parent?: HierarchicalLocation;
  parents?: HierarchicalLocation[];
}

export interface Industry {
  id: number;
  name: string;
}

export const SubscriptionPlanType = {
  FREE: 'FREE',
  GROWTH: 'GROWTH',
  SCALE: 'SCALE',
  UNLIMITED: 'UNLIMITED',
  CUSTOMIZED: 'CUSTOMIZED',
} as const;

export type SubscriptionPlanType =
  (typeof SubscriptionPlanType)[keyof typeof SubscriptionPlanType];

export type CompanyTalentSearchChats = {
  talentSearchChatsBalance: number;
};

export type CurrentCompanyJobSlotsSubscription = {
  totalConcurrentJobSlots: number;
  activeConcurrentJobs: number;
  expiryDateTime?: string;
  isConcurrentJobsHardLimitReached: boolean;
  hasUnlimitedConcurrentJobSlots: boolean;
  planType: SubscriptionPlanType;
  translatedPlanName: string;
  hasCSVDownload?: boolean | null;
  hasVIPMembershipAccess: boolean;
  hasJobExternalUrl: boolean;

  /**
   * @deprecated This field is deprecated and will be removed in future versions.
   * This field refer to the talent search balance prior to self serve.
   * It refers to the value from employer_commerce.SubscriptionTalentChatQuotas.
   * After self serve, the value will be taken from employer_purchases.CompanyProductInventories.
   * Use the Company.talentSearchBalance field instead.
   */ talentSearchChats?: CompanyTalentSearchChats | null;
};
export interface CompanyUser {
  id: string;
  UserId: string;
  links: {
    user: User;
  };
  role: CompanyUserRole;
  status?: string;
  newRole: string;
  toBeRemoved: boolean;
}

export type TalentJobRolePreference = {
  hierarchicalJobCategory: {
    translation_key: string;
    defaultName: string;
    id: string;
  };
};

export type JobRolePreference = {
  HierarchicalJobCategory: {
    translationKey: string;
    defaultName: string;
    id: string;
  };
};

export type TalentEducation = {
  id: string;
  educationLevel: EDUCATION_LEVEL;
  fieldOfStudy: string;
  study: string;
  school: string;
  startDate: string;
  endDate: string;
  isPresent: boolean;
};

export type Talent = {
  id: string;
  name: string;
  birthDate: string;
  Nationality: string;
  candidateStatus: string;
  gender: GENDER;
  intro: string;
  lastSeen: string;
  profilePic: string;
  createdAt: string;
  highestEducationLevel: EDUCATION_LEVEL;
  LocationId: string;
  experiences: Array<WorkExperience> | null;
  Experiences?: Array<Experience>;
  educations: Array<TalentEducation> | null;
  workDurationInMonths: number;
  locationFormattedNames: Array<HierarchicalLocation>;
  location: string;
  updatedAt?: string;
  salary: {
    latest: number;
    expectation: number;
    currencyCode: string;
  };
  skills: Array<Skill>;
  JobRolePreferences: Array<TalentJobRolePreference>;
  jobRolePreferences: Array<TalentJobRolePreference>;
  profilePics: Array<{ key: string }>;
  isTalentBookmarked: boolean;
  latestEducationExperience: TalentEducation;
  latestWorkExperience: WorkExperience;
  hasResume?: boolean;
  resume?: string | null;
  isUnlocked?: boolean;
  phone?: string;
  whatsappNumber?: string;
  email?: string;
  ccas: Array<Experience> | null;
  awards: Array<Experience> | null;
  certificates: Array<Experience> | null;
};

export interface TalentBookmark
  extends Omit<
    Talent,
    | 'Experiences'
    | 'locationFormattedNames'
    | 'updatedAt'
    | 'salary'
    | 'JobRolePreferences'
    | 'jobRolePreferences'
    | 'isTalentBookmarked'
    | 'profilePics'
    | 'hasResume'
    | 'resume'
  > {
  hierarchicalLocation: HierarchicalLocation;
  salary: {
    salaryLatest: number | null;
    salaryExpectation: number | null;
    maxSalaryExpectation: number | null;
    CurrencyCode: string;
  };
  jobRolePreferences: {
    HierarchicalJobCategory: {
      defaultName: string;
      id: string;
    };
  }[];
}

export interface UnlockedTalent
  extends Omit<
    Talent,
    | 'Experiences'
    | 'locationFormattedNames'
    | 'updatedAt'
    | 'salary'
    | 'JobRolePreferences'
    | 'jobRolePreferences'
    | 'isTalentBookmarked'
    | 'profilePics'
  > {
  hierarchicalLocation: HierarchicalLocation;
  salary: {
    salaryLatest: number | null;
    salaryExpectation: number | null;
    maxSalaryExpectation: number | null;
    CurrencyCode: string;
  };
  jobRolePreferences: {
    HierarchicalJobCategory: {
      defaultName: string;
      id: string;
    };
  }[];
}

export type BaseEmployerInput = {
  companyId: string;
};

export const EmployerPurchaseProductType = {
  HOT_JOB: 'HOT_JOB',
  RECOMMENDED_TALENT_CHAT: 'RECOMMENDED_TALENT_CHAT',
  VIP_MEMBERSHIP: 'VIP_MEMBERSHIP',
  TALENT_SEARCH: 'TALENT_SEARCH',
} as const;

export type EmployerPurchaseProductType =
  (typeof EmployerPurchaseProductType)[keyof typeof EmployerPurchaseProductType];
