import { gql, skipToken, useSuspenseQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

import { EmployerPurchaseProductType } from '../../../common/interfaces/entities';
import { getSessionCompanyId } from '../../../selectors/session';

const query = gql`
  query getProductCreditBenefit($input: GetProductCreditBenefitInput!) {
    getProductCreditBenefit(input: $input) {
      amount
    }
  }
`;

type QueryVariables = {
  input: {
    productType: string;
    companyId: string;
  };
};

type QueryResponse = {
  getProductCreditBenefit:
    | {
        amount: number;
      }
    | undefined;
};

type UseGetProductBenefit = {
  productType: EmployerPurchaseProductType;
  skip?: boolean;
};

export default function useGetProductBenefit({
  productType,
  skip,
}: UseGetProductBenefit) {
  const companyId = useSelector(getSessionCompanyId);
  const { data } = useSuspenseQuery<QueryResponse, QueryVariables>(
    query,
    !companyId || skip
      ? skipToken
      : {
          variables: {
            input: {
              productType,
              companyId,
            },
          },
        }
  );

  return {
    productCreditBenefit: data?.getProductCreditBenefit,
  } as const;
}
