import { useState } from 'react';

import { useAxiosWithAPI } from '../../../common/hooks/useAxiosWithAPI';
import { DraftResponse } from '../../../common/interfaces/responses';
import { JobDraftFormState, JobDraftPostingPayload } from '../common/constants';
import useConvertJobData from './useConvertJobData';

const useDraft = () => {
  const axios = useAxiosWithAPI();
  const { convertJobFormStateToPayload } = useConvertJobData();

  const [isLoading, setIsLoading] = useState(false);

  const createDraft = async (
    payload: JobDraftPostingPayload
  ): Promise<DraftResponse> => {
    setIsLoading(true);

    try {
      const res = await axios.post('/jobDrafts', { data: payload });
      return res.data;
    } finally {
      setIsLoading(false);
    }
  };

  const updateDraft = async (
    draftId: string,
    payload: JobDraftPostingPayload
  ): Promise<DraftResponse> => {
    setIsLoading(true);

    try {
      const res = await axios.put(`/jobDrafts/${draftId}`, { data: payload });
      return res.data;
    } catch {
      throw new Error('Failed to create draft');
    } finally {
      setIsLoading(false);
    }
  };

  const upsertDraft = async (data: JobDraftFormState, jobDraftId?: string) => {
    if (jobDraftId) {
      const response = await updateDraft(
        jobDraftId,
        convertJobFormStateToPayload(data)
      );

      return response.data;
    } else {
      const response = await createDraft(convertJobFormStateToPayload(data));

      return response.data;
    }
  };

  return {
    updateDraft,
    createDraft,
    upsertDraft,
    isLoading,
  };
};

export default useDraft;
