import PhoneNumber from 'awesome-phonenumber';

import { COUNTRY_CALLING_CODES } from '../constants';

const indonesiaPhoneRegex = /^\+628[0-9]{7,12}$/;

export function isValidPhoneNumber(
  callingCode: string,
  phoneNumber: string,
  countryCode?: string
): boolean {
  const pn = `${callingCode}${phoneNumber}`;
  if (!PhoneNumber(pn, countryCode).isValid()) {
    return false;
  }

  if (callingCode === COUNTRY_CALLING_CODES.id) {
    return indonesiaPhoneRegex.test(pn);
  }

  return true;
}
