import { defineMessages, IntlShape, MessageDescriptor } from 'react-intl';
import * as Yup from 'yup';

import { USER_ROLES } from '../../../common/constants';
import { isValidPhoneNumber } from '../../../common/helpers/phone';
import config from '../../../config';
import { getMobileNumberCountryCode } from '../helpers';

const MIN_PASSWORD_LENGTH = 6;

export interface SignUpFormState {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  role: string;
  password: string;
}

export const defaultSignUpFormState: SignUpFormState = {
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  role: USER_ROLES.COMPANY,
  password: '',
};

export const errorMessages = defineMessages({
  invalidEmail: {
    id: 'text-please-enter-valid-email',
    defaultMessage: 'Please enter a valid email address',
  },
  blockedEmailDomain: {
    id: 'text-this-email-domain-is-blocked',
    defaultMessage:
      'This email domain is blocked by Glints. Reach out to Contact Support for help.',
  },
  minLengthEmail: {
    id: 'text-password-min-six-characters',
    defaultMessage: 'The password should have at least 6 characters',
  },
  required: {
    id: 'text-this-field-is-required',
    defaultMessage: 'This field is required',
  },
  invalidPhone: {
    id: 'text-enter-valid-phone-number',
    defaultMessage: 'Please enter a valid phone number',
  },
  emailAlreadyTaken: {
    id: 'text-email-has-been-taken',
    defaultMessage: 'This email has been taken',
  },
  somethingWentWrong: {
    id: 'text-something-went-wrong',
    defaultMessage: 'Something went wrong. Please try again later.',
  },
});

export const signUpValidationSchema = (
  formatMessage: IntlShape['formatMessage']
) =>
  Yup.object().shape({
    email: Yup.string()
      .email(formatMessage(errorMessages.invalidEmail))
      .required(formatMessage(errorMessages.required)),
    firstName: Yup.string().required(formatMessage(errorMessages.required)),
    lastName: Yup.string().required(formatMessage(errorMessages.required)),
    phone: Yup.string()
      .required(formatMessage(errorMessages.required))
      .test({
        name: 'phone-number',
        test: function (this: any, phoneNumber: any) {
          const phoneNumberWithoutSpaces = phoneNumber?.replace(/\s/g, '');
          const countryCode = getMobileNumberCountryCode();
          const validPhoneNumberBasedOnCountry = isValidPhoneNumber(
            countryCode,
            phoneNumberWithoutSpaces,
            config.COUNTRY
          );

          if (
            phoneNumber &&
            (!validPhoneNumberBasedOnCountry || isNaN(phoneNumberWithoutSpaces))
          ) {
            return this.createError({
              message: formatMessage(errorMessages.invalidPhone),
              path: 'phone',
            });
          }
          return true;
        },
      }),
    password: Yup.string()
      .min(MIN_PASSWORD_LENGTH, formatMessage(errorMessages.minLengthEmail))
      .required(formatMessage(errorMessages.required)),
  });

export const COMPANY_SIZES = {
  SELF_EMPLOYED: 'SELF_EMPLOYED',
  BETWEEN_1_AND_10: 'BETWEEN_1_AND_10',
  BETWEEN_11_AND_50: 'BETWEEN_11_AND_50',
  BETWEEN_51_AND_200: 'BETWEEN_51_AND_200',
  BETWEEN_201_AND_500: 'BETWEEN_201_AND_500',
  BETWEEN_501_AND_1000: 'BETWEEN_501_AND_1000',
  BETWEEN_1001_AND_5000: 'BETWEEN_1001_AND_5000',
  BETWEEN_5001_AND_10000: 'BETWEEN_5001_AND_10000',
  MORE_THAN_10000: 'MORE_THAN_10000',
};

export type CompanySize = keyof typeof COMPANY_SIZES;

type CompanySizeMessageParams = {
  minHeadcount?: number;
  maxHeadcount?: number;
};

export const getCompanySizeMessageDescriptor = (
  companySize?: CompanySize
): [MessageDescriptor, CompanySizeMessageParams] => {
  if (companySize === COMPANY_SIZES.SELF_EMPLOYED) {
    return [
      {
        id: 'interactive-self-employed',
        defaultMessage: 'Self Employed',
      },
      {},
    ];
  } else if (companySize === COMPANY_SIZES.MORE_THAN_10000) {
    return [
      {
        id: 'interactive-more-than-employees',
        defaultMessage: '{minHeadcount}+ employees',
      },
      {
        minHeadcount: Number(companySize?.split('_')[2]),
      },
    ];
  } else {
    return [
      {
        id: 'interactive-headcount-number-employees',
        defaultMessage: '{minHeadcount} - {maxHeadcount} employees',
      },
      {
        minHeadcount: Number(companySize?.split('_')[1]),
        maxHeadcount: Number(companySize?.split('_')[3]),
      },
    ];
  }
};
