import { defineMessages, IntlShape } from 'react-intl';
import * as Yup from 'yup';

import { USER_ROLES } from '../../../common/constants';
import { isValidPhoneNumber } from '../../../common/helpers/phone';
import { INDONESIA_COUNTRY } from '../../../modules/JobV2/common/constants';

export const ID_CALLING_CODE = 62;

const MIN_PASSWORD_LENGTH = 6;

export interface SignUpFormState {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  role: string;
  password: string;
  confirmPassword: string;
}

export const defaultSignUpFormState: SignUpFormState = {
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  role: USER_ROLES.COMPANY,
  password: '',
  confirmPassword: '',
};

export const errorMessages = defineMessages({
  invalidEmail: {
    id: 'text-please-enter-valid-email',
    defaultMessage: 'Please enter a valid email address',
  },
  minLengthEmail: {
    id: 'text-password-min-six-characters',
    defaultMessage: 'The password should have at least 6 characters',
  },
  required: {
    id: 'text-this-field-is-required',
    defaultMessage: 'This field is required',
  },
  invalidPhone: {
    id: 'text-enter-valid-phone-number',
    defaultMessage: 'Please enter a valid phone number',
  },
  passwordDoesNotMatch: {
    id: 'text-password-do-not-match',
    defaultMessage: 'Passwords do not match',
  },
  emailAlreadyTaken: {
    id: 'text-email-has-been-taken',
    defaultMessage: 'This email has been taken',
  },
  somethingWentWrong: {
    id: 'text-something-went-wrong',
    defaultMessage: 'Something went wrong. Please try again later.',
  },
});

export const signUpValidationSchema = (
  formatMessage: IntlShape['formatMessage']
) =>
  Yup.object().shape({
    email: Yup.string()
      .email(formatMessage(errorMessages.invalidEmail))
      .required(formatMessage(errorMessages.required)),
    firstName: Yup.string().required(formatMessage(errorMessages.required)),
    lastName: Yup.string().required(formatMessage(errorMessages.required)),
    phone: Yup.string()
      .required(formatMessage(errorMessages.required))
      .test({
        name: 'phone-number',
        test: function (this: any, phoneNumber: any) {
          if (
            phoneNumber &&
            !isValidPhoneNumber(
              `+${ID_CALLING_CODE}`,
              phoneNumber,
              INDONESIA_COUNTRY.code
            )
          ) {
            return this.createError({
              message: formatMessage(errorMessages.invalidPhone),
              path: 'phone',
            });
          }
          return true;
        },
      }),
    password: Yup.string()
      .min(MIN_PASSWORD_LENGTH, formatMessage(errorMessages.minLengthEmail))
      .required(formatMessage(errorMessages.required)),
    confirmPassword: Yup.string().when('password', (confirmPassword, schema) =>
      schema
        .required(formatMessage(errorMessages.required))
        .oneOf(
          [confirmPassword],
          formatMessage(errorMessages.passwordDoesNotMatch)
        )
    ),
  });
